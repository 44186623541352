import Footer from '../../Footer/Footer';
import Navbar from '../../Navbar/Navbar';

import 'aos/dist/aos.css';
import AOS from 'aos';
import paperless from '../../../images/paperlesssheet.png';
import favicon from '../../../images/logo2.png'
import { Helmet } from 'react-helmet';



import { useEffect } from 'react';





const Paperless: React.FC = () => {

  useEffect(() => {
    AOS.init();
  }, []);


  return (
    <>

<Helmet>
  {/* SEO Optimized Title */}
  <title>Moisture & Mold-Resistant Gypsum Board Suppliers in Mumbai - White Panther Gypsum</title>

  {/* Optimized Meta Description */}
  <meta name="description" content="White Panther Gypsum is a leading manufacturer & supplier of high-performance moisture and mold-resistant gypsum boards in Mumbai. Designed for wet and high-humidity areas, our non-paper-faced, glass mat-reinforced boards offer superior durability." />

  {/* Keyword Optimization */}
  <meta name="keywords" content="Moisture-Resistant Gypsum Board, Mold-Resistant Plasterboard, Waterproof Gypsum Board, High-Humidity Gypsum Board, Glass Mat Gypsum Board, UV-Coated Gypsum Board, Non-Paper Faced Gypsum, Gypsum Board for Wet Areas, Gypsum Board Manufacturers in Mumbai, Gypsum Board Suppliers in Mumbai, Construction Materials, White Panther Gypsum" />

  {/* Favicon */}
  <link rel="icon" href={favicon} type="image/png" />

  {/* Open Graph for Social Media Sharing */}
  <meta property="og:title" content="Moisture & Mold-Resistant Gypsum Board Suppliers in Mumbai - White Panther Gypsum" />
  <meta property="og:description" content="Get high-quality moisture and mold-resistant gypsum boards from White Panther Gypsum in Mumbai. Ideal for wet areas and high-humidity environments, our non-paper-faced, glass mat-reinforced boards ensure superior performance." />
  <meta property="og:image" content="http://www.whitepanthergypsum.com/static/media/paperlesssheet.b11b522774d2d6cc43d7.png" />
  <meta property="og:url" content="https://www.whitepanthergypsum.com/Paperless" />
  <meta property="og:type" content="website" />

  {/* Twitter Card for Social Sharing */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Moisture & Mold-Resistant Gypsum Board Suppliers in Mumbai - White Panther Gypsum" />
  <meta name="twitter:description" content="Looking for moisture and mold-resistant gypsum boards? White Panther Gypsum manufactures & supplies premium-quality gypsum boards for wet and high-humidity areas in Mumbai." />
  <meta name="twitter:image" content="https://www.whitepanthergypsum.com/Paperless/gypsum-board.jpg" />

  {/* Schema Markup for Local Business SEO */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "White Panther Gypsum - Moisture & Mold-Resistant Gypsum Board Manufacturers",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "XYZ Road",
          "addressLocality": "Mumbai",
          "addressRegion": "MH",
          "postalCode": "400001",
          "addressCountry": "IN"
        },
        "telephone": "+91-XXXXXXXXXX",
        "url": "https://www.whitepanthergypsum.com/Paperless",
        "sameAs": [
          "https://facebook.com/whitepanthergypsum",
          "https://linkedin.com/company/whitepanthergypsum"
        ]
      }
    `}
  </script>
</Helmet>
     <Navbar></Navbar>

     <div className="relative flex flex-col-reverse py-16 lg:py-0 lg:flex-col m-10" data-aos="fade-right"
     data-aos-offset="600"
     data-aos-easing="ease-in-sine">
  <div className="w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:py-20 lg:max-w-screen-xl">
    <div className="mb-0 lg:max-w-lg lg:pr-8 xl:pr-6">
      <h2 className="bg-gradient-to-r from-purple-600 to-pink-500 text-transparent bg-clip-text font-bold text-center text-3xl sm:text-4xl md:text-5xl uppercase">
      WP-Gypsum 
        <br className="hidden md:block" />
        paperless sheet
      </h2>
      <p className="mb-5 text-base text-gray-700 font-semibold sm:text-lg md:text-2xl text-justify pt-4">
      
This paperless gypsum board with mat reinforcement offers inherent mold resistance. Its glass fiber mat, protected by a hydrophobic and UV-resistant coating, enhances durability. The board's gypsum core contains additives for moisture and mold resistance, and it is reinforced with glass-mat on both surfaces. Free from cellulose, it strongly resists mold growth, making it ideal for wet and high-humidity areas, including exterior applications.     
      </p>
      <div className="flex flex-col md:flex-row items-center justify-center md:items-start">
        <a href="https://wa.me/919819600149" className="inline-flex items-center justify-center h-12 px-6 mb-4 md:mr-6 md:mb-0 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-gradient-to-r from-cyan-500 to-blue-500 focus:shadow-outline focus:outline-none hover:text-white">
          Get Quote
        </a>
      </div>
    </div>
  </div>
  <div className="inset-y-0 top-0 right-0 w-full max-w-xl px-4 mx-auto mb-6 md:px-0 lg:pl-8 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-1/2 lg:max-w-full lg:absolute xl:px-0" data-aos="flip-left"
       data-aos-easing="ease-out-cubic"
       data-aos-duration="2000">
    <img className="object-cover h-56 rounded shadow-lg lg:rounded-none lg:shadow-2xl md:h-96 lg:h-full" src={paperless} alt="" />
  </div>
  </div>

<div className="flex justify-center text-2xl mt-32 uppercase" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
  <div className="w-full">
    <h1 className="text-4xl xs:text-xl sm:text-xl md:text-4xl font-bold bg-center text-center">Technical Specification</h1>
    <div className="flex justify-center">
      <table className="table-auto mb-8 mt-6 w-full max-w-2xl mx-5">
        <thead>
          <tr className="bg-gray-100 text-base">
            <th className="border px-4 py-2">Thickness</th>
            <th className="border px-4 py-2">Width</th>
            <th className="border px-4 py-2">Length</th>
            <th className="border px-4 py-2">Edge Type</th>
          </tr>
        </thead>
        <tbody className="text-base xs:text-xs sm:text-xs  md:text-base text-center">
         
          <tr className="bg-white">
            <td className="border px-4 py-2">12.5 mm</td>
            <td className="border px-4 py-2">1200 mm</td>
            <td className="border px-4 py-2">1830 mm</td>
            <td className="border px-4 py-2">T/E S/E</td>
          </tr>
          <tr className="bg-white">
            <td className="border px-4 py-2">12.5 mm</td>
            <td className="border px-4 py-2">1220 mm</td>
            <td className="border px-4 py-2">2400 mm</td>
            <td className="border px-4 py-2">T/E S/E</td>
          </tr>
          <tr className="bg-white">
            <td className="border px-4 py-2">12.5 mm</td>
            <td className="border px-4 py-2">1220 mm</td>
            <td className="border px-4 py-2">2440 mm</td>
            <td className="border px-4 py-2">T/E S/E</td>
          </tr>
          
        </tbody>
      </table>
    </div>
  </div>
</div>

<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" data-aos="fade-up" data-aos-anchor-placement="top-center">
  <h5 className="mb-8 text-4xl xs:text-3xl font-extrabold leading-none text-center md:text-left underline md:text-4xl">Application</h5>
  <div className="grid ">
    <ul className="space-y-3">
      <li className="flex items-start text-xl xs:text-base md:text-xl">
        <span className="mr-1">
          <svg className="w-5 h-5 mt-px text-deep-purple-accent-400" stroke="currentColor" viewBox="0 0 52 52">
            <polygon strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23" />
          </svg>
        </span>
The Paperless Sheet is highly suitable for exterior use, offering exceptional performance in outdoor environments. Its robust design and resistance to moisture and mold make it an ideal choice for various exterior applications.
      </li>
    </ul>
  </div>
</div>

<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
  <h5 className="mb-8 text-4xl xs:text-3xl font-extrabold leading-none text-center md:text-left underline  md:text-4xl">Classification</h5>
  <div className="grid ">
    <ul className="space-y-3">
      <li className="flex items-start text-xl xs:text-base md:text-xl">
        <span className="mr-1">
          <svg className="w-5 h-5 mt-px text-deep-purple-accent-400" stroke="currentColor" viewBox="0 0 52 52">
            <polygon strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23" />
          </svg>
        </span>
        Front side  White-faced glass mat liner reverse side plain white
      </li>
    </ul>
  </div>
</div>

<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
  <h5 className="mb-8 text-4xl xs:text-3xl font-extrabold leading-none text-center md:text-left underline  md:text-4xl">Thermal Conductivity</h5>
  <div className="grid ">
    <ul className="space-y-3">
      <li className="flex items-start text-xl xs:text-base md:text-xl">
        <span className="mr-1">
          <svg className="w-5 h-5 mt-px text-deep-purple-accent-400" stroke="currentColor" viewBox="0 0 52 52">
            <polygon strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23" />
          </svg>
        </span>
       0.3 (W/mk)
      </li>
    </ul>
  </div>
</div>

<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
<h5 className="mb-8 text-4xl md:text-4xl font-extrabold leading-none md:pl-2 xs:text-3xl xs:text-center md:text-left underline">
  Features
</h5>

      <div className="grid gap-3 row-gap-3 lg:grid-cols-3 xs:text-base md:text-xl">
        <ul className="space-y-3">
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
            Mould and Moisture Resistance
          </li>
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
            High Impact Resistance
          </li>
         
   
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
            Energy Efficiency
          </li>
         
        
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
            Dimensional Stability
          </li>

          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
            Flexibility
          </li>

         
        </ul>
       
 
        
        
      </div>
    </div>

     <Footer></Footer>
    </>
  );
};

export default Paperless;
