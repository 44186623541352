import './style.css';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import Slider from '../Slider/Slider.jsx';
import Aos from 'aos';
import 'aos/dist/aos.css'
import favicon from '../../images/logo2.png'
import { Helmet } from 'react-helmet';

const Home: React.FC = () => {
  
  return (
    <>
<Helmet>
    <title>Top Gypsum Powder Suppliers & Manufacturers in Mumbai, Maharashtra | White Panther Gypsum</title>
    <link rel="icon" href={favicon} />
    <meta name="description" content="White Panther Gypsum - Leading gypsum powder manufacturers & suppliers in Mumbai. Get premium quality gypsum products, bulk orders, and reliable delivery across Maharashtra." />
    
  
    <meta name="keywords" content="
        gypsum powder suppliers Mumbai,gypsum manufacturers near Mumbai,gypsum powder manufacturers Maharashtra,industrial gypsum suppliers Mumbai,construction gypsum powder,best gypsum powder brands,gypsum for wall plaster,bulk gypsum powder suppliers,Mumbai gypsum company,gypsum powder price per kg,B2B gypsum suppliers,gypsum export from Mumbai" />

   
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Mumbai's Trusted Gypsum Powder Manufacturers | White Panther Gypsum" />
    <meta property="og:description" content="Premium gypsum powder suppliers in Mumbai offering construction-grade materials, bulk orders, and pan-India delivery. ISO-certified manufacturing." />
    <meta property="og:image" content="URL_TO_FACTORY_IMAGE" />
    <meta property="og:url" content="https://www.whitepanthergypsum.com/gypsum-powder-mumbai-suppliers" />


    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Gypsum Powder Manufacturers Mumbai | White Panther Gypsum" />
    <meta name="twitter:description" content="Leading suppliers of industrial & construction gypsum powder in Mumbai region. Competitive pricing & quality certification." />
    <meta name="twitter:image" content="URL_TO_PRODUCT_IMAGE" />

  
    <script type="application/ld+json">
    {`
        {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "White Panther Gypsum",
            "image": "URL_TO_LOGO",
            "@id": "",
            "url": "https://www.whitepanthergypsum.com",
            "telephone": "+91-XXXXX-XXXXX",
            "address": {
                "@type": "PostalAddress",
                "streetAddress": "Your Street Address",
                "addressLocality": "Mumbai",
                "postalCode": "400001",
                "addressRegion": "Maharashtra",
                "addressCountry": "India"
            },
            "geo": {
                "@type": "GeoCoordinates",
                "latitude": 19.0760,
                "longitude": 72.8777
            },
            "priceRange": "₹₹-₹₹₹",
            "sameAs": [
                "URL_TO_SOCIAL_PROFILES"
            ]
        }
    `}
    </script>

   
    <link rel="canonical" href="https://www.whitepanthergypsum.com/gypsum-powder-mumbai-suppliers" />
    

    <meta name="robots" content="index, follow, max-image-preview:large" />
    

    <meta name="author" content="White Panther Gypsum Mumbai" />
</Helmet>


      <Navbar />
      
      <div className="flex justify-center" >
        <div className="w-full lg:max-w-5xl xl:max-w-6xl lg:flex lg:justify-between"> 
          <div className="mb-8 lg:mb-0 lg:w-1/2 lg:pr-8"> 
            <div className="relative px-4 pt-16 mx-auto lg:py-32 md:px-8 xl:px-5 sm:max-w-xl md:max-w-full">
              <div className="max-w-xl mx-auto lg:max-w-screen-xl">
                <div className="mb-16 lg:max-w-lg lg:mb-0">
                  <div className="max-w-xl mb-6">
                  
              
                  
                  <div data-aos="fade-right"
                       data-aos-offset="50"
                       data-aos-easing="ease-in-sine">

                    <h2 className="max-w-lg mb-6 font-sans text-3xl tracking-tight text-gray-900 sm:text-5xl sm:leading-none"   >
                      From gypsum powders to wall panels,

                      <br className="hidden md:block" />
                      explore a full spectrum of {' '}
                      
                      <span className="bg-gradient-to-r from-purple-600 to-pink-500 text-transparent bg-clip-text font-bold">
                      gypsum solutions
                      </span>
                    </h2>
        </div>          
                  </div>
                  <div className="flex flex-col md:flex-row items-center md:items-start md:justify-between" >
                    <a
                      href="/PlasterBoards"
                       className="inline-flex items-center justify-center h-12 px-6 mb-4 md:mr-6 md:mb-0 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-gradient-to-r from-cyan-500 to-blue-500 focus:shadow-outline focus:outline-none hover:text-white"
                    >
                      Explore
                    </a>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 lg:pl-2 lg:pt-10 flex justify-center items-center"> {/* Centering and aligning items */}
            <div className="w-full max-w-screen-md"> {/* Adjusted maximum width */}
              <Slider />
            </div>
          </div>
        </div>
      </div>

      
      <div className="relative bg-gray-900"  data-aos="zoom-out-up">
  <div className="absolute inset-x-0 bottom-0" >
    <svg
      viewBox="0 0 224 12"
      fill="currentColor"
      className="w-full -mb-1 text-white"
      preserveAspectRatio="none"
    >
      <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
    </svg>
  </div>
  <div data-aos="zoom-out-left">
  <div className="px-4 py-16 mx-auto sm:max-w-3xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" > 
    <div className="relative max-w-3xl sm:mx-auto sm:max-w-3xl md:max-w-3xl sm:text-center" > 
      <h2 className="mb-6 font-sans text-3xl font-bold tracking-tight text-white sm:text-4xl sm:leading-none">
        <span className="relative inline-block px-2">
          <div className="absolute inset-0 transform -skew-x-12 bg-gray-50" />
          <span className="relative text-gray-900" >About Us</span>
        </span>
      </h2>
      <p className="mb-6 text-base text-indigo-100 md:text-2xl">
        Welcome to White Panther Gypsum, a premier supplier of high-quality gypsum products worldwide. With expertise in trading and exporting gypsum, we provide tailored solutions to meet your needs. Committed to excellence and reliability, we ensure every product meets the highest industry standards. Trust White Panther Gypsum for all your gypsum requirements.
      </p> 
    </div>
  </div>
</div>
</div>
  
<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        
        <h2 className="max-w-lg mb-6 font-sans text-xl font-bold leading-none tracking-tight text-gray-900 sm:text-6xl md:mx-auto">
          <span className="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="d5589eeb-3fca-4f01-ac3e-983224745704"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                fill="url(#d5589eeb-3fca-4f01-ac3e-983224745704)"
                width="52"
                height="24"
              />
            </svg>
            <span className="relative">We have</span>
          </span>{' '}
          Supplied more than 
        </h2>
    
      </div>
<div className="flex justify-center px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
  <div className="grid grid-cols-2 row-gap-8 md:grid-cols-3">
    <div className="text-center md:border-r ">
      <h6 className="text-4xl font-bold lg:text-5xl xl:text-6xl">150K</h6>
      <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base m-4">
        Gypsum Plaster Boards
      </p>
    </div>

    
    
    <div className="text-center md:border-r p-1">
      <h6 className="text-4xl font-bold lg:text-5xl xl:text-6xl">90 TONS</h6>
      <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base m-4">
        Gypsum Powder
      </p>
    </div>
    
    <div className="text-center ">
      <h6 className="text-4xl font-bold lg:text-5xl xl:text-6xl">50 TONS</h6>
      <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base m-4">
        Plaster Bond
      </p>
    </div>
    
    
    

    
  </div>
</div>

   
    </div>
    
      <Footer />
    </>
  );
};

export default Home;
