import Footer from '../../Footer/Footer';
import Navbar from '../../Navbar/Navbar';

import 'aos/dist/aos.css';
import AOS from 'aos';
import royalbond from '../../../images/royalbond.png';
import favicon from '../../../images/logo2.png'
import { Helmet } from 'react-helmet';



import { useEffect } from 'react';





const Royalbond: React.FC = () => {

  useEffect(() => {
    AOS.init();
  }, []);


  return (
    <>
 <Helmet>
  {/* Optimized Title for Google Ranking */}
  <title>Gypsum Bonding Agent - Best Suppliers & Manufacturers in Mumbai | White Panther Gypsum</title>

  {/* SEO-Optimized Meta Description */}
  <meta name="description" content="White Panther Gypsum Bonding Agent ensures superior adhesion for gypsum and sand-cement plaster on RCC surfaces. Available from leading manufacturers and suppliers in Mumbai." />

  {/* Keyword-Rich Meta Keywords */}
  <meta name="keywords" content="Gypsum Bonding Agent, Plaster Bonding Agent, RCC Surface Bonding, Sand-Cement Plaster Adhesion, White Panther Gypsum, Gypsum Adhesive, Construction Chemicals, Mumbai Suppliers, Mumbai Manufacturers, Best Plaster Bonding Agent" />

  {/* Favicon */}
  <link rel="icon" href={favicon} type="image/png" />

  {/* Open Graph for Social Sharing */}
  <meta property="og:title" content="Gypsum Bonding Agent - Best Suppliers & Manufacturers in Mumbai" />
  <meta property="og:description" content="Buy high-quality gypsum bonding agent for strong adhesion on RCC surfaces. White Panther Gypsum supplies the best bonding agent for gypsum and sand-cement plaster in Mumbai." />
  <meta property="og:image" content="https://www.whitepanthergypsum.com/static/media/royalbond.f7af74ebc9e1ae8c50c7.png" />
  <meta property="og:url" content="https://www.whitepanthergypsum.com/RoyalBond" />
  <meta property="og:type" content="product" />

  {/* Twitter Card for Social Media */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Gypsum Bonding Agent - Best Suppliers in Mumbai | White Panther Gypsum" />
  <meta name="twitter:description" content="Looking for the best gypsum bonding agent? White Panther Gypsum offers high-quality bonding solutions for RCC surfaces. Available from leading manufacturers & suppliers in Mumbai." />
  <meta name="twitter:image" content="https://www.whitepanthergypsum.com/static/media/royalbond.f7af74ebc9e1ae8c50c7.png" />

  {/* Schema Markup for SEO Boost */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": "Gypsum Bonding Agent",
        "brand": {
          "@type": "Brand",
          "name": "White Panther Gypsum"
        },
        "description": "High-performance bonding agent for gypsum and sand-cement plaster. Ideal for RCC surfaces, ceilings, and aluminum formwork, providing strong adhesion.",
        "image": "https://www.whitepanthergypsum.com/static/media/royalbond.f7af74ebc9e1ae8c50c7.png",
        "offers": {
          "@type": "Offer",
          "url": "https://www.whitepanthergypsum.com/RoyalBond",
          "priceCurrency": "INR",
          "availability": "https://schema.org/InStock"
        }
      }
    `}
  </script>
</Helmet>
     <Navbar></Navbar>


     <div className="relative flex flex-col-reverse py-16 lg:py-0 lg:flex-col m-10" data-aos="fade-right"
     data-aos-offset="600"
     data-aos-easing="ease-in-sine">
      <div className="w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:py-20 lg:max-w-screen-xl">
        <div className="mb-0 lg:max-w-lg lg:pr-8 xl:pr-6">
          <h2 className="bg-gradient-to-r from-purple-600 to-pink-500 text-transparent bg-clip-text font-bold text-center md:text-8xl sm:text-5xl">
            Royal
            <br className="hidden md:block" />
            Bond
          </h2>
          <p className="mb-5 text-base text-gray-700 font-semibold md:text-2xl md:text-justify text-justify pt-4">
          The bonding agent is tailored for gypsum and sand-cement plaster, expediting the readiness of ceiling and wall surfaces, notably on RCC surfaces, in just 24 hours. It eliminates the risk of detachment of gypsum or sand and cement plaster from substrates with low suction, like RCC surfaces (including aluminum formwork and ceilings). Crafted with a unique blend of polymers, additives, and sand fillers, it provides dual benefits by ensuring both mechanical and chemical adhesion.
          </p>

          <div className="flex flex-col md:flex-row items-center justify-center md:items-start ">
            <a
             href="https://wa.me/919819600149"
                 className="inline-flex items-center justify-center h-12 px-6 mb-4 md:mr-6 md:mb-0 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-gradient-to-r from-cyan-500 to-blue-500 focus:shadow-outline focus:outline-none hover:text-white"
             >
               Get Quote
             </a>
         </div>

    
        </div>
      </div>
      <div className="inset-y-0 top-0 right-0 w-full max-w-xl px-4 mx-auto mb-6 md:px-0 lg:pl-8 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-1/2 lg:max-w-full lg:absolute xl:px-0"  data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">
        <img
          className="object-cover  h-56 rounded shadow-lg lg:rounded-none lg:shadow-2xl md:h-96 lg:h-full"
          src={royalbond}
          alt=""
        />
      </div>
    </div>


    <div className="flex justify-center text-2xl" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
  <div className="w-full">
    <div className="flex justify-center">
      <table className="table-auto mb-8 mt-10">
        <thead>
          <tr>
            <th className="px-12 py-2 w-1/2">Technical Specification</th>
            <th className="px-12 py-2 w-1/2">Value</th>
          </tr>
        </thead>
        <tbody> 
          <tr className="bg-gray-100">
            <td className="border px-4 py-2">Drying Time</td>
            <td className="border px-4 py-2">1 Day</td>
          </tr>
          <tr className="bg-white">
            <td className="border px-4 py-2">Open Time for Plastering</td>
            <td className="border px-4 py-2">2 to 10 Days</td>
          </tr>
          <tr className="bg-white">
            <td className="border px-4 py-2">Coverage</td>
            <td className="border px-4 py-2">5 m²/kg*</td>
          </tr>
          <tr className="bg-white">
            <td className="border px-4 py-2">Ambient Temperature Requirement</td>
            <td className="border px-4 py-2">5 to 45 °C</td>
          </tr>
          <tr className="bg-white">
            <td className="border px-4 py-2">Pull Out Strength</td>
            <td className="border px-4 py-2">less than 1.01MPa </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

   

    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
    <h5 className="mb-8 text-4xl font-extrabold leading-none md:pl-2 underline">
  Features
</h5>

      <div className="grid gap-3 row-gap-3 lg:grid-cols-3">
        <ul className="space-y-3">
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
           Dual Bonding Chemical and Mechanical Key
          </li>
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
           Single Coat Brush Application
          </li>
         
        </ul>
        <ul className="space-y-3">
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
            No Trackiness Required 
          </li>
         
        
        </ul>
        <ul className="space-y-3">
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
            Green Color
          </li>
        </ul>
      </div>
    </div>


    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
      <h5 className=" mb-8 text-4xl font-extrabold leading-none md:pl-2 underline">
        Benifits
      
      </h5>
      <div className="grid gap-3 row-gap-3 lg:grid-cols-3">
      <ul className="space-y-3">
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
           2X More Strength
          </li>
          
         
        </ul>
        <ul className="space-y-3">
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
           10 X Faster Application
          </li>
 
         
        </ul>
        <ul className="space-y-3">
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
            Easy Supervision
          </li>
        
         
        </ul>
      </div>
    </div>



    





     <Footer></Footer>
    </>
  );
};

export default Royalbond;
