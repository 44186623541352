import Footer from '../../Footer/Footer';
import Navbar from '../../Navbar/Navbar';

import 'aos/dist/aos.css';
import AOS from 'aos';
import royalmr from '../../../images/royalmr.png';
import favicon from '../../../images/logo2.png'
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';





const RoyalMR: React.FC = () => {

  useEffect(() => {
    AOS.init();
  }, []);


  return (
    <>

<Helmet>
  {/* Optimized Title for Google Ranking */}
  <title>Royal-HR Humidity Resistant Gypsum Plaster - Best for Damp Areas | White Panther Gypsum</title>

  {/* SEO-Optimized Meta Description */}
  <meta name="description" content="Royal-HR Humidity Resistant Gypsum Plaster by White Panther Gypsum is designed for bathrooms, kitchens, basements, and parking podiums. It minimizes moisture absorption and prevents efflorescence. Available from top manufacturers & suppliers in Mumbai." />

  {/* Keyword-Rich Meta Keywords */}
  <meta name="keywords" content="Royal-HR Gypsum Plaster, Humidity Resistant Plaster, Moisture-Proof Plaster, Anti-Efflorescence Plaster, Gypsum Plaster for Bathrooms, Kitchen Plaster, Parking Podium Plaster, White Panther Gypsum, Plaster Suppliers in Mumbai" />

  {/* Favicon */}
  <link rel="icon" href={favicon} type="image/png" />

  {/* Open Graph for Social Sharing */}
  <meta property="og:title" content="Royal-HR Gypsum Plaster - Best Humidity Resistant Solution | White Panther Gypsum" />
  <meta property="og:description" content="White Panther Gypsum Royal-HR Plaster is engineered for high-humidity environments like kitchens, basements, and bathrooms. It prevents moisture absorption and efflorescence." />
  <meta property="og:image" content="https://www.whitepanthergypsum.com/images/static/media/royalmr.6440fd725ae29773ff45.png" />
  <meta property="og:url" content="https://www.whitepanthergypsum.com/Royalhr" />
  <meta property="og:type" content="product" />

  {/* Twitter Card for Social Media */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Royal-HR Gypsum Plaster - Best for Humid Environments | White Panther Gypsum" />
  <meta name="twitter:description" content="Royal-HR Plaster is an advanced humidity-resistant gypsum plaster designed to protect against moisture in kitchens, basements, and bathrooms. Available in Mumbai." />
  <meta name="twitter:image" content="https://www.whitepanthergypsum.com/images/static/media/royalmr.6440fd725ae29773ff45.png" />

  {/* Schema Markup for SEO Boost */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": "Royal-HR Humidity Resistant Gypsum Plaster",
        "brand": {
          "@type": "Brand",
          "name": "White Panther Gypsum"
        },
        "description": "Royal-HR is a humidity-resistant single-layer gypsum plaster designed to protect against moisture absorption in wet areas like bathrooms, kitchens, basements, garages, balconies, and parking podiums.",
        "image": "https://www.whitepanthergypsum.com/images/static/media/royalmr.6440fd725ae29773ff45.png",
        "offers": {
          "@type": "Offer",
          "url": "https://www.whitepanthergypsum.com/Royalhr",
          "priceCurrency": "INR",
          "availability": "https://schema.org/InStock"
        }
      }
    `}
  </script>
</Helmet>
     <Navbar></Navbar>


     <div className="relative flex flex-col-reverse py-16 lg:py-0 lg:flex-col m-10" data-aos="fade-right"
     data-aos-offset="600"
     data-aos-easing="ease-in-sine">
      <div className="w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:py-20 lg:max-w-screen-xl">
        <div className="mb-0 lg:max-w-lg lg:pr-8 xl:pr-6">
          <h2 className="bg-gradient-to-r from-purple-600 to-pink-500 text-transparent bg-clip-text font-bold text-center md:text-8xl sm:text-5xl">
            Royal
            <br className="hidden md:block" />
            HR
          </h2>
          <p className="mb-5 text-base text-gray-700 font-semibold md:text-2xl md:text-justify text-justify pt-4">
           
Royal-HR (Humidity Resistance) is a single-layer gypsum plaster engineered to offer safeguarding in damp environments such as bathrooms, kitchens, basements, garages, balconies, and parking podiums. It delivers added defense by minimizing moisture absorption from the air or surfaces, effectively addressing issues related to capillary action and efflorescence in skirting areas. It can be directly applied over substrates like bricks, blocks, and RCC.
          </p>

          <div className="flex flex-col md:flex-row items-center justify-center md:items-start ">
            <a
             href="https://wa.me/919819600149"
                 className="inline-flex items-center justify-center h-12 px-6 mb-4 md:mr-6 md:mb-0 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-gradient-to-r from-cyan-500 to-blue-500 focus:shadow-outline focus:outline-none hover:text-white"
             >
               Get Quote
             </a>
         </div>

    
        </div>
      </div>
      <div className="inset-y-0 top-0 right-0 w-full max-w-xl px-4 mx-auto mb-6 md:px-0 lg:pl-8 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-1/2 lg:max-w-full lg:absolute xl:px-0"  data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">
        <img
          className="object-cover  h-56 rounded shadow-lg lg:rounded-none lg:shadow-2xl md:h-96 lg:h-full"
          src={royalmr}
          alt=""
        />
      </div>
    </div>


    <div className="flex justify-center text-2xl" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
  <div className="w-full">
    <div className="flex justify-center">
      <table className="table-auto mb-8 mt-10">
        <thead>
          <tr>
            <th className="px-12 py-2 w-1/2">Technical Specification</th>
            <th className="px-12 py-2 w-1/2">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-gray-100">
            <td className="border px-4 py-2">Wet Bulk Density</td>
            <td className="border px-4 py-2">1.4 to 1.5 g/cm³</td>
          </tr>
          <tr className="bg-white">
            <td className="border px-4 py-2">Dry Bulk Density</td>
            <td className="border px-4 py-2">Upto 880 Kg/m³</td>
          </tr>
          <tr className="bg-white">
            <td className="border px-4 py-2">Water To Powder Ratio</td>
            <td className="border px-4 py-2">1:1.25</td>
          </tr>
          <tr className="bg-white">
            <td className="border px-4 py-2">Setting Time</td>
            <td className="border px-4 py-2">25-35 Minutes</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

   

    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
    <h5 className="mb-8 text-4xl font-extrabold leading-none md:pl-2 underline">
  Features
</h5>

      <div className="grid gap-3 row-gap-3 lg:grid-cols-3">
        <ul className="space-y-3">
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
           Total Water Absorption less than 10%
          </li>
         
         
        </ul>
        <ul className="space-y-3">
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
            Higher Setting Time
          </li>
         
        
        </ul>
        <ul className="space-y-3">
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
            Low Thermal Conductivity
          </li>
        </ul>
      </div>
    </div>


    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
      <h5 className=" mb-8 text-4xl font-extrabold leading-none md:pl-2 underline">
        Benifits
      
      </h5>
      <div className="grid gap-3 row-gap-3 lg:grid-cols-3">
      <ul className="space-y-3">
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
            50% Lighter than Conventional Plaster
          </li>
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
             Saves Water
          </li>
         
        </ul>
        <ul className="space-y-3">
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
           2 X Faster Construction 
          </li>
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
            Energy Saving
          </li>
         
        </ul>
        <ul className="space-y-3">
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
            Low Wastage
          </li>

        </ul>
      </div>
    </div>



    





     <Footer></Footer>
    </>
  );
};

export default RoyalMR;
