import { Link } from 'react-router-dom';
import './style.css';

const Footer: React.FC = () => {
  return (
    <>
      <footer className="footer p-4 md:p-10 bg-neutral-900 text-white flex flex-wrap justify-center md:justify-between gap-4 md:gap-10 md:pl-48 md:pr-48 text-sm md:text-base lg:text-lg xs:text-base">
        <nav className="text-center mb-4 md:mb-0">
          <h6 className="footer-title">Products</h6>
          <a><Link to="/PlasterBoards" >Plaster Boards</Link></a>
          <a><Link to="/plaster" >Plaster & Bond</Link></a>
          <a><Link to="/fixing-fastening-accessories">Framing and Accessories</Link></a>
        </nav>

        <nav className="text-center mb-4 md:mb-0">
          <h6 className="footer-title">Company</h6>
          <a><Link to="/">About Us</Link></a>
          <a><Link to="/contact">Contact Us</Link></a>
        </nav>

        <nav className="mb-4 md:mb-0">
  <h6 className="footer-title">Address</h6>
  <a href="https://www.google.co.in/maps/place/KK+Chambers,+Dr+Dadabhai+Naoroji+Rd,+Azad+Maidan,+Fort,+Mumbai,+Maharashtra+400001/data=!4m2!3m1!1s0x3be7d1dc0d3b42ef:0xbb4045be9a75e71f?sa=X&ved=1t:242&ictx=111">
    White Panther Gypsum, 3rd Floor, Plot 4, Office No. 9, K. K. Chambers,<br />
    Purshotamdas Thakurdas Road, Near Siddharth College,<br />
    Fort, Mumbai - 400001
  </a>

  <h6 className="footer-title">Pan India No.</h6>
  <a href="tel:+919819600149">+91 9819600149</a>

  <h6 className="footer-title">Email</h6>
  <a href="mailto:salesgypsum@hotmail.com">salesgypsum@hotmail.com</a>
</nav>


<form className="text-center" action="https://formsubmit.co/salesgypsum@hotmail.com" method="POST">
  <h6 className="footer-title">Newsletter</h6>
  <fieldset className="form-control w-full md:w-80 mx-auto">
    <label className="label">
      <span className="label-text text-sm md:text-base lg:text-lg xs:text-base text-white">Enter your email address</span>
    </label>
    <div className="join flex items-center justify-center">
      <input type="text" placeholder="username@site.com" className="input input-bordered join-item bg-white text-black" />
      <button type="submit" className="btn bg-sky-600 join-item">Subscribe</button>
    </div>
  </fieldset>
</form>

      </footer>
      <footer className='footer px-10 py-4 border-t  bg-neutral-900 text-white  border-base-300 flex justify-center'>
        <aside className='text-sm md:text-base lg:text-lg xs:text-base'>
          <p>© Copyright 2025 | All Rights Reserved by <a href='http://www.spaceageelectrical.com/'>Space Age Networks Limited</a></p>
        </aside>
      </footer>
    </>
  );
};

export default Footer;
