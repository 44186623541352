import Footer from '../../Footer/Footer';
import Navbar from '../../Navbar/Navbar';

import 'aos/dist/aos.css';
import AOS from 'aos';
import sheethr from '../../../images/sheethr.png';
import favicon from '../../../images/logo2.png'
import { Helmet } from 'react-helmet';


import { useEffect } from 'react';





const Sheet: React.FC = () => {

  useEffect(() => {
    AOS.init();
  }, []);


  return (
    <>

<Helmet>
  {/* SEO Optimized Title */}
  <title>Humidity-Resistant Plasterboard - White Panther-GYPSUM Sheet Humidity Resistance Suppliers in Mumbai</title>

  {/* Optimized Meta Description */}
  <meta name="description" content="WP-GYPSUM Sheet HR is a premium humidity-resistant plasterboard designed for high-moisture areas like bathrooms and kitchens. Manufactured with durable green paper liners, it provides superior moisture protection for walls and ceilings in homes and commercial buildings. Available from top manufacturers and suppliers in Mumbai." />

  {/* Keyword Optimization */}
  <meta name="keywords" content="Humidity-Resistant Plasterboard, Moisture-Resistant Gypsum Board, WP-GYPSUM Sheet HR, Bathroom Gypsum Board, Kitchen Plasterboard, Waterproof Plasterboard, Green Paper Liner Gypsum Board, Gypsum Board Suppliers in Mumbai, Gypsum Board Manufacturers in Mumbai, Moisture Protection for Walls, Durable Construction Materials" />

  {/* Favicon */}
  <link rel="icon" href={favicon} type="image/png" />

  {/* Open Graph for Social Media Sharing */}
  <meta property="og:title" content="Humidity-Resistant Plasterboard - WP-GYPSUM Sheet HR | Best Suppliers in Mumbai" />
  <meta property="og:description" content="Get high-quality humidity-resistant plasterboards for bathrooms, kitchens, and high-moisture areas. WP-GYPSUM Sheet HR offers superior durability and moisture protection. Available from leading suppliers in Mumbai." />
  <meta property="og:image" content="https://www.whitepanthergypsum.com/static/media/sheethr.a3cffde5e9e2ab52cc26.png" />
  <meta property="og:url" content="https://www.whitepanthergypsum.com/Sheet" />
  <meta property="og:type" content="product" />

  {/* Twitter Card for Social Sharing */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Humidity-Resistant Plasterboard - WP-GYPSUM Sheet HR | Best Suppliers in Mumbai" />
  <meta name="twitter:description" content="Looking for a high-quality humidity-resistant plasterboard? WP-GYPSUM Sheet HR is perfect for wet areas like bathrooms and kitchens. Available from trusted manufacturers and suppliers in Mumbai." />
  <meta name="twitter:image" content="https://www.whitepanthergypsum.com/static/media/sheethr.a3cffde5e9e2ab52cc26.png" />

  {/* Schema Markup for Local Business SEO */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": "Humidity-Resistant Plasterboard - WP-GYPSUM Sheet HR",
        "brand": {
          "@type": "Brand",
          "name": "White Panther Gypsum"
        },
        "description": "WP-GYPSUM Sheet HR is a premium humidity-resistant plasterboard designed for high-moisture areas like bathrooms and kitchens. It features durable green paper liners for superior moisture protection.",
        "image": "https://www.whitepanthergypsum.com/static/media/sheethr.a3cffde5e9e2ab52cc26.png",
        "offers": {
          "@type": "Offer",
          "url": "https://www.whitepanthergypsum.com/Sheet",
          "priceCurrency": "INR",
          "availability": "https://schema.org/InStock"
        }
      }
    `}
  </script>
</Helmet> 
     <Navbar></Navbar>

     <div className="relative flex flex-col-reverse py-16 lg:py-0 lg:flex-col m-10" data-aos="fade-right"
     data-aos-offset="600"
     data-aos-easing="ease-in-sine">
  <div className="w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:py-20 lg:max-w-screen-xl">
    <div className="mb-0 lg:max-w-lg lg:pr-8 xl:pr-6">
      <h2 className="bg-gradient-to-r from-purple-600 to-pink-500 text-transparent bg-clip-text font-bold text-center text-3xl sm:text-4xl md:text-5xl uppercase">
      WP-GYPSUM 
        <br className="hidden md:block" />
        SHEET HR
      </h2>
      <p className="mb-5 text-base text-gray-700 font-semibold sm:text-lg md:text-2xl text-justify pt-4">
      Humidity-Resistant plasterboard is ideal for humid areas like bathrooms and kitchens, offering reliable protection against moisture-related issues. Its durable construction and green paper liners provide both functionality and aesthetics for resilient walls and ceilings in homes or commercial buildings.
      </p>
      <div className="flex flex-col md:flex-row items-center justify-center md:items-start">
        <a href="https://wa.me/919819600149" className="inline-flex items-center justify-center h-12 px-6 mb-4 md:mr-6 md:mb-0 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-gradient-to-r from-cyan-500 to-blue-500 focus:shadow-outline focus:outline-none hover:text-white">
          Get Quote
        </a>
      </div>
    </div>
  </div>
  <div className="inset-y-0 top-0 right-0 w-full max-w-xl px-4 mx-auto mb-6 md:px-0 lg:pl-8 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-1/2 lg:max-w-full lg:absolute xl:px-0" data-aos="flip-left"
       data-aos-easing="ease-out-cubic"
       data-aos-duration="2000">
    <img className="object-cover h-56 rounded shadow-lg lg:rounded-none lg:shadow-2xl md:h-96 lg:h-full" src={sheethr} alt="" />
  </div>
  </div>

<div className="flex justify-center text-2xl mt-32 uppercase" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
  <div className="w-full">
    <h1 className="text-4xl xs:text-xl sm:text-xl md:text-4xl font-bold bg-center text-center">Technical Specification</h1>
    <div className="flex justify-center">
      <table className="table-auto mb-8 mt-6 w-full max-w-2xl mx-5">
        <thead>
          <tr className="bg-gray-100 text-base">
            <th className="border px-4 py-2">Thickness</th>
            <th className="border px-4 py-2">Width</th>
            <th className="border px-4 py-2">Length</th>
            <th className="border px-4 py-2">Edge Type</th>
          </tr>
        </thead>
        <tbody className="text-base xs:text-xs sm:text-xs  md:text-base text-center">
          <tr className="bg-white">
            <td className="border px-4 py-2">9.5 mm</td>
            <td className="border px-4 py-2">1219 mm</td>
            <td className="border px-4 py-2">1829 mm</td>
            <td className="border px-4 py-2">T/E S/E</td>
          </tr>
          <tr className="bg-white">
            <td className="border px-4 py-2">12.5 mm</td>
            <td className="border px-4 py-2">1219 mm</td>
            <td className="border px-4 py-2">1829 mm</td>
            <td className="border px-4 py-2">T/E S/E</td>
          </tr>
          <tr className="bg-white">
            <td className="border px-4 py-2">12.5 mm</td>
            <td className="border px-4 py-2">1219 mm</td>
            <td className="border px-4 py-2">2438 mm</td>
            <td className="border px-4 py-2">T/E S/E</td>
          </tr>
          <tr className="bg-white">
            <td className="border px-4 py-2">15 mm</td>
            <td className="border px-4 py-2">1219 mm</td>
            <td className="border px-4 py-2">1829 mm</td>
            <td className="border px-4 py-2">T/E S/E</td>
          </tr>
          <tr className="bg-white">
            <td className="border px-4 py-2">15 mm</td>
            <td className="border px-4 py-2">1219 mm</td>
            <td className="border px-4 py-2">2438 mm</td>
            <td className="border px-4 py-2">T/E S/E</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" data-aos="fade-up" data-aos-anchor-placement="top-center">
  <h5 className="mb-8 text-4xl xs:text-3xl font-extrabold leading-none text-center md:text-left underline md:text-4xl">Application</h5>
  <div className="grid ">
    <ul className="space-y-3">
      <li className="flex items-start text-xl xs:text-base md:text-xl">
        <span className="mr-1">
          <svg className="w-5 h-5 mt-px text-deep-purple-accent-400" stroke="currentColor" viewBox="0 0 52 52">
            <polygon strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23" />
          </svg>
        </span>
        Ideal for use on walls, ceilings, corridors, and auditoriums in various buildings such as offices, schools, hospitals, and homes.
      </li>
    </ul>
  </div>
</div>

<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
  <h5 className="mb-8 text-4xl xs:text-3xl font-extrabold leading-none text-center md:text-left underline  md:text-4xl">Classification</h5>
  <div className="grid ">
    <ul className="space-y-3">
      <li className="flex items-start text-xl xs:text-base md:text-xl">
        <span className="mr-1">
          <svg className="w-5 h-5 mt-px text-deep-purple-accent-400" stroke="currentColor" viewBox="0 0 52 52">
            <polygon strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23" />
          </svg>
        </span>
        Gray facing paper Brown backside paper
      </li>
    </ul>
  </div>
</div>

<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
  <h5 className="mb-8 text-4xl xs:text-3xl font-extrabold leading-none text-center md:text-left underline  md:text-4xl">Thermal Conductivity</h5>
  <div className="grid ">
    <ul className="space-y-3">
      <li className="flex items-start text-xl xs:text-base md:text-xl">
        <span className="mr-1">
          <svg className="w-5 h-5 mt-px text-deep-purple-accent-400" stroke="currentColor" viewBox="0 0 52 52">
            <polygon strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23" />
          </svg>
        </span>
       0.16 (W/mk)
      </li>
    </ul>
  </div>
</div>

<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20" data-aos="fade-up"
     data-aos-anchor-placement="top-center">
<h5 className="mb-8 text-4xl md:text-4xl font-extrabold leading-none md:pl-2 xs:text-3xl xs:text-center md:text-left underline">
  Features
</h5>

      <div className="grid gap-3 row-gap-3 lg:grid-cols-3 xs:text-base md:text-xl">
        <ul className="space-y-3">
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
           Humidity Resistant
          </li>
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
           Single Coat Brush Application
          </li>
         
   
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
            Ease Of Finishing
          </li>
         
        
          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
            Aesthetically Stunning Ceilings
          </li>

          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
            Crack-Free Drywalls & Ceilings
          </li>

          <li className="flex items-start">
            <span className="mr-1">
              <svg
                className="w-5 h-5 mt-px text-deep-purple-accent-400"
                stroke="currentColor"
                viewBox="0 0 52 52"
              >
                <polygon
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  points="29 13 14 29 25 29 23 39 38 23 27 23"
                />
              </svg>
            </span>
            Finishing Options For Drywalls
          </li>
        </ul>
       
 
        
        
      </div>
    </div>
    
     <Footer></Footer>
    </>
  );
};

export default Sheet;
