import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules'; 
import 'swiper/css';
import 'swiper/css/effect-cards';
import slide1 from '../../images/1.png' ;
import slide2 from '../../images/2.png';
import slide3 from '../../images/3.png' ;
import slide4 from '../../images/4.png';
import slide5 from '../../images/5.png';
import slide6 from '../../images/6.png';
import slide7 from '../../images/7.png';
import slide8 from '../../images/8.png';
import slide9 from '../../images/9.png';
import slide10 from '../../images/10.png';
import slide11 from '../../images/11.png';
import slide12 from '../../images/12.png';
import slide13 from '../../images/13.png';
import slide14 from '../../images/14.png';
import slide15 from '../../images/15.png';
import slide16 from '../../images/16.png';
import slide17 from '../../images/17.png';
import slide18 from '../../images/18.png';
import slide19 from '../../images/19.png';
import slide20 from '../../images/20.png';
import slide21 from '../../images/21.png';
import slide22 from '../../images/12.png';
import slide23 from '../../images/23.png';
import slide24 from '../../images/24.png';


const Slider = () => {
  return (
    <Swiper effect={'cards'} modules={[EffectCards]} className="mySwiper">
      <SwiperSlide> <img src= {slide1} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide2} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide3} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide4} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide5} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide6} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide7} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide8} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide9} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide10} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide11} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide12} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide13} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide14} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide15} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide16} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide17} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide18} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide19} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide20} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide21} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide22} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide23} alt="Slide 1" /></SwiperSlide>
      <SwiperSlide> <img src= {slide24} alt="Slide 1" /></SwiperSlide>

    </Swiper>
  );
};

export default Slider;