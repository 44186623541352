import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import './style.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import plaster from '../../images/plaster.png';
import royalplus from '../../images/royalplus.png';
import royal from '../../images/royal.png'

import royalmr from '../../images/royalmr.png';
import royalbond from '../../images/royalbond.png';
import logo2 from '../../images/logo2.png';

import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import favicon from '../../images/logo2.png'
import { Helmet } from 'react-helmet';



const Plaster: React.FC = () => {

  useEffect(() => {
    AOS.init();
  }, []);


  return (
    <>
    
    <Helmet>
  {/* Optimized SEO Title */}
  <title>Gypsum Powder & Bonding Agent Suppliers and Manufactures in Mumbai - White Panther Gypsum</title>

  {/* Optimized Meta Description */}
  <meta name="description" content="White Panther Gypsum is a leading manufacturer & supplier of premium gypsum powder and bonding agents in Mumbai. High-quality construction materials for durable interior solutions." />

  {/* Keyword Optimization */}
  <meta name="keywords" content="Gypsum Powder, Gypsum Bonding Agent, Gypsum Plaster, Bonding Plaster, Gypsum Board Suppliers, Gypsum Manufacturers, Mumbai, White Panther Gypsum, Construction Materials" />

  {/* Favicon */}
  <link rel="icon" href={favicon} type="image/png" />

  {/* Open Graph for Social Media Sharing */}
  <meta property="og:title" content="Gypsum Powder & Bonding Agent Suppliers in Mumbai - White Panther Gypsum" />
  <meta property="og:description" content="Get premium quality gypsum powder and bonding agents from White Panther Gypsum in Mumbai. Trusted suppliers for durable and high-performance construction materials." />
  <meta property="og:image" content="https://www.whitepanthergypsum.com/plaster/image.jpg" />
  <meta property="og:url" content="https://www.whitepanthergypsum.com/plaster" />
  <meta property="og:type" content="website" />

  {/* Twitter Card for Social Sharing */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Gypsum Powder & Bonding Agent Suppliers in Mumbai - White Panther Gypsum" />
  <meta name="twitter:description" content="Find top-quality gypsum powder and bonding agents from White Panther Gypsum in Mumbai. Durable and high-performance solutions for construction." />
  <meta name="twitter:image" content="https://www.whitepanthergypsum.com/plaster/image.jpg" />

  {/* Schema Markup for Local Business SEO */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "White Panther Gypsum - Gypsum Powder & Bonding Agent Manufacturers",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "XYZ Road",
          "addressLocality": "Mumbai",
          "addressRegion": "MH",
          "postalCode": "400001",
          "addressCountry": "IN"
        },
        "telephone": "+91-9819600149",
        "url": "https://www.whitepanthergypsum.com/plaster",
        "sameAs": [
          "https://facebook.com/whitepanthergypsum",
          "https://linkedin.com/company/whitepanthergypsum"
        ]
      }
    `}
  </script>
</Helmet>
     <Navbar></Navbar>


<div className="relative  sm:w-full  h-24 md:w-full md:h-96 lg:w-100 md:w-100">
  <div className="absolute inset-0">
    <img
      className="w-full  sm:h-3 sm:w-100 md:h-96 object-cover"
      src={plaster}
      alt=""
    />
    <div className="absolute inset-0 bg-slate-100 mix-blend-multiply" aria-hidden="true" />
  </div>
  <div className="relative max-w-7xl py-12 px-4 sm:py-24 sm:px-6 lg:px-8 md:pt-40">
    <h2 className="max-w-44 mb-6 leading-tight tracking-tight sm:text-3xl md:text-5xl lg:text-7xl text-white font-bold text pt-2">
      Plaster Solutions
    </h2>
  </div>
</div>


<main className="dark:bg-gray-800 bg-white relative overflow-hidden mx-10 z-0 pt-20 bg-opacity-0">
    <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden">
        <div className="container mx-auto px-6 flex relative py-16 flex-col sm:flex-row">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20 order-2 sm:order-1"  data-aos="flip-left">
                <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
                <h1 className="font-sans uppercase text-4xl sm:text-6xl flex items-center bg-gradient-to-r from-yellow-300 to-pink-700 text-transparent bg-clip-text">
     <span className="font-bold">Royal Plus (+)</span>
    <span className="text-2xl ml-2 lowercase text-black">by</span>
    <img src={logo2} alt="Logo" className="h-10 ml-3"/>
</h1>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
             Available in polypropylene bags weighing 25 Kgs.
                </li>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
              Coverage at a thickness of 13mm under laboratory conditions, packaged in 25 kg bags.
                </li>
                <div className="flex mt-8 gap-3">
                  
                    <Link to="/Royalplus" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md ">
                        Read more
                    </Link>

                    <Link to="https://wa.me/919819600149" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                     Get Quote       
                     </Link>

                </div>
            </div>
            <div className="sm:w-1/3 lg:w-3/5 relative order-1 sm:order-2 ">
    <img src={royalplus} className="w-full max-w-lg sm:max-w-full md:max-w-xl mx-auto shadow-2xl " data-aos="zoom-in-left"/>
</div>
        </div>
    </div>
</main>


<main className="dark:bg-gray-800 bg-white relative overflow-hidden z-1 p-10">
    <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden m-6">
        <div className="container mx-auto px-6 flex relative py-16 sm:flex-row flex-col-reverse">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20 order-1 sm:order-2" data-aos="flip-left">
                <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
                             <h1 className="font-sans uppercase text-4xl sm:text-6xl flex items-center bg-gradient-to-r from-yellow-300 to-pink-700 text-transparent bg-clip-text">
     <span className="font-bold">Royal </span>
    <span className="text-2xl ml-2 lowercase text-black">by</span>
    <img src={logo2} alt="Logo" className="h-10 ml-4"/>
</h1>

                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
             Available in polypropylene bags weighing 25 Kgs.
                </li>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
              Coverage at a thickness of 13mm under laboratory conditions, packaged in 25 kg bags.
                </li>
                <div className="flex mt-8 gap-3">
                <Link to="/one-coat-plaster" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Read more
                    </Link>

                    <a href="https://wa.me/919819600149" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Get Quote
                    </a>
                </div>
                
            </div>
            <div className="sm:w-1/3 lg:w-3/5 relative order-2 sm:order-1" >
            <img src={royal} className="w-full max-w-lg sm:max-w-full md:max-w-xl mx-auto shadow-2xl " data-aos="zoom-in-right"/>
            </div>
        </div>
    </div>
</main>

<main className="dark:bg-gray-800 bg-white relative overflow-hidden mx-10 z-1 pt-20 pb-28">
    <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden">
        <div className="container mx-auto px-6 flex relative py-16 flex-col sm:flex-row">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20 order-2 sm:order-1"  data-aos="flip-right">
                <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
                           <h1 className="font-sans uppercase text-4xl sm:text-6xl flex items-center bg-gradient-to-r from-yellow-300 to-pink-700 text-transparent bg-clip-text">
     <span className="font-bold">Royal HR</span>
    <span className="text-2xl ml-2 lowercase text-black">by</span>
    <img src={logo2} alt="Logo" className="h-10 ml-4"/>
</h1>

                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
             Available in polypropylene bags weighing 25 Kgs.
                </li>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
              Coverage at a thickness of 13mm under laboratory conditions, packaged in 25 kg bags.
                </li>
                <div className="flex mt-8 gap-3">
                  
                <Link to="/royal-hr-plaster" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Read more
                    </Link>

                    
                    <a href="https://wa.me/919819600149" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Get Quote
                    </a>
                </div>
            </div>
            
            <div className="sm:w-1/3 lg:w-3/5 relative order-1 sm:order-2">
               <img src={royalmr} className="w-full max-w-lg sm:max-w-full md:max-w-xl mx-auto shadow-2xl " data-aos="zoom-in-left"/> 
            </div>
        </div>
    </div>
</main>

<main className="dark:bg-gray-800 bg-white relative overflow-hidden z-1 p-10">
    <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden m-6">
        <div className="container mx-auto px-6 flex relative py-16 sm:flex-row flex-col-reverse">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20 order-1 sm:order-2"  data-aos="flip-left">
                <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
                <h1 className="font-sans uppercase text-4xl sm:text-6xl flex items-center bg-gradient-to-r from-yellow-300 to-pink-700 text-transparent bg-clip-text">
     <span className="font-bold">Royal Bond</span>
    <span className="text-2xl ml-2 lowercase text-black">by</span>
    <img src={logo2} alt="Logo" className="h-10 ml-4"/>
</h1>

                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
                Offered in plastic buckets weighing 1 kg, 5 kg, 10 kg, and 20 kg.
                </li>
                <li className="text-sm sm:text-2xl text-gray-700 dark:text-white">
                Coverage achievable with a single coat application under laboratory conditions
                </li>
                <div className="flex mt-8 gap-3">
                <Link to="/gypsum-bonding-agent" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Read more
                    </Link>

                    <a href="#" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Get Quote
                    </a>
                </div>
                
            </div>
            <div className="sm:w-1/3 lg:w-3/5 relative order-2 sm:order-1">
            <img src={royalbond} className="w-full max-w-lg sm:max-w-full md:max-w-xl mx-auto shadow-2xl " data-aos="zoom-in-right"/>
            </div>
        </div>
    </div>
</main>

     <Footer></Footer>
    </>
  );
};

export default Plaster;
