import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import './style.css';
import 'aos/dist/aos.css';
import AOS from 'aos';
import plaster from '../../images/boardheading.png';
import sheethr from '../../images/sheethr.png';
import standard from '../../images/standardsheet.png'

import paperless from '../../images/paperlesssheet.png';
import royalbond from '../../images/royalbond.png';
import logo2 from '../../images/logo2.png';

import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import favicon from '../../images/logo2.png'
import { Helmet } from 'react-helmet';





const Plasterboard: React.FC = () => {

  useEffect(() => {
    AOS.init();
  }, []);


  return (
    <>
<Helmet>
  {/* SEO Optimized Title */}
  <title>Gypsum Plaster Boards - Best Quality from White Panther Gypsum</title>

  {/* Optimized Meta Description */}
  <meta name="description" content="White Panther Gypsum offers premium-quality Gypsum Plaster Boards, including standard, humidity-resistant, and fire-resistant boards. Ideal for walls and ceilings." />

  {/* Keyword Optimization */}
  <meta name="keywords" content="Gypsum Plaster Board, Gypsum Boards, Plaster Boards, Wall Panels, Ceiling Boards, Fire-Resistant Plasterboard, Moisture-Resistant Gypsum Board, Gypsum Sheet Suppliers, Construction Materials" />

  {/* Favicon */}
  <link rel="icon" href={favicon} type="image/png" />

  {/* Open Graph for Social Media Sharing */}
  <meta property="og:title" content="Gypsum Plaster Boards - Best Quality from White Panther Gypsum" />
  <meta property="og:description" content="Explore high-quality Gypsum Plaster Boards for walls and ceilings, available in fire-resistant, moisture-resistant, and standard variants. Contact White Panther Gypsum today!" />
  <meta property="og:image" content="https://www.whitepanthergypsum.com/http://www.whitepanthergypsum.com/static/media/royalplus.3a9465e16da7647c59fa.png" />
  <meta property="og:url" content="https://www.whitepanthergypsum.com/plaster" />
  <meta property="og:type" content="website" />

  {/* Twitter Card for Social Sharing */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Gypsum Plaster Boards - Best Quality from White Panther Gypsum" />
  <meta name="twitter:description" content="Find premium Gypsum Plaster Boards for strong, durable walls and ceilings. Available in multiple variants for construction and interiors." />
  <meta name="twitter:image" content="https://www.whitepanthergypsum.com/gypsum-plaster-board.jpg" />

  {/* Schema Markup for Local Business SEO */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": "Gypsum Plaster Board",
        "brand": {
          "@type": "Brand",
          "name": "White Panther Gypsum"
        },
        "description": "High-quality Gypsum Plaster Boards for walls and ceilings, available in fire-resistant, moisture-resistant, and standard options.",
        "image": "https://www.whitepanthergypsum.com/gypsum-plaster-board.jpg",
        "offers": {
          "@type": "Offer",
          "url": "https://www.whitepanthergypsum.com",
          "priceCurrency": "INR",
          "availability": "https://schema.org/InStock"
        }
      }
    `}
  </script>
</Helmet>
     <Navbar></Navbar>
     <div className="relative  sm:w-full  h-24 md:w-full md:h-96 lg:w-100 md:w-100">
  <div className="absolute inset-0">
    <img
      className="w-full  sm:h-3 sm:w-100 md:h-96 object-cover"
      src={plaster}
      alt=""
    />
    <div className="absolute inset-0 bg-slate-100 mix-blend-multiply" aria-hidden="true" />
  </div>
  <div className="relative max-w-7xl py-12 px-4 sm:py-24 sm:px-6 lg:px-8 md:pt-40">
    <h2 className="max-w-44 mb-6 leading-tight tracking-tight sm:text-4xl md:text-5xl lg:text-7xl text-white font-bold text pt-2">
      Plaster Boards
    </h2>
  </div>
</div>


<main className="dark:bg-gray-800 bg-white relative overflow-hidden mx-10 z-0 pt-20 bg-opacity-0">
    <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden">
        <div className="container mx-auto px-6 flex relative py-16 flex-col sm:flex-row">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20 order-2 sm:order-1"  data-aos="flip-left">
                <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
                <h1 className="font-sans uppercase text-4xl sm:text-2xl flex items-center xs:text-base bg-gradient-to-r from-yellow-300 to-pink-700 text-transparent bg-clip-text">
     <span className="font-bold">WP-Gypsum sheet (standard)</span>
    <span className="text-xl  lowercase text-black ml-3">by</span>
    <img src={logo2} alt="Logo" className="h-10 ml-3"/>
</h1>


                <p className="text-sm sm:text-2xl text-gray-700 dark:text-white md:text-xl mt-3">
                Plain plasterboard consists of an aerated gypsum core encased in, and firmly bonded to, strong paper liners.
                </p>
               
                <div className="flex mt-8 gap-3">
                  
                    <Link to="/Standard" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md ">
                        Read more
                    </Link>

                    <Link to="https://wa.me/919819600149" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                      Get Quote
                   </Link>

                </div>
            </div>
            <div className="sm:w-1/3 lg:w-3/5 relative order-1 sm:order-2 ">
    <img src={standard} className="w-full max-w-lg sm:max-w-full md:max-w-xl mx-auto shadow-2xl " data-aos="zoom-in-left"/>
</div>
        </div>
    </div>
</main>


<main className="dark:bg-gray-800 bg-white relative overflow-hidden z-1 p-10">
    <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden m-6">
        <div className="container mx-auto px-6 flex relative py-16 sm:flex-row flex-col-reverse">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20 order-1 sm:order-2" data-aos="flip-left">
                <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
                             <h1 className="font-sans uppercase text-8xl xs:text-base sm:text-2xl flex items-center bg-gradient-to-r from-yellow-300 to-pink-700 text-transparent bg-clip-text ">
     <span className="font-bold">WP-Gypsum sheet HR</span>
    <span className="text-2xl lowercase text-black ml-3">by</span>
    <img src={logo2} alt="Logo" className="h-10 ml-4"/>
</h1>

                <p className="text-sm sm:text-2xl text-gray-700 dark:text-white md:text-xl mt-3">
                  Humidity-resistant plasterboard is made of an aerated gypsum core with water-repellent additives, encased in and firmly bonded to robust paper liners.
                </p>
                
                <div className="flex mt-8 gap-3">
                <Link to="/Sheet" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Read more
                    </Link>

                    <a href="https://wa.me/919819600149" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Get Quote
                    </a>
                </div>
                
            </div>
            <div className="sm:w-1/3 lg:w-3/5 relative order-2 sm:order-1" >
            <img src={sheethr} className="w-full max-w-lg sm:max-w-full md:max-w-xl mx-auto shadow-2xl " data-aos="zoom-in-right"/>
            </div>
        </div>
    </div>
</main>

<main className="dark:bg-gray-800 bg-white relative overflow-hidden mx-10 z-1 pt-20 pb-28">
    <div className="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden">
        <div className="container mx-auto px-6 flex relative py-16 flex-col sm:flex-row">
            <div className="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20 order-2 sm:order-1"  data-aos="flip-right">
                <span className="w-20 h-2 bg-gray-800 dark:bg-white mb-12"></span>
                           <h1 className="font-sans uppercase text-8xl sm:text-2xl xs:text-base flex items-center bg-gradient-to-r from-yellow-300 to-pink-700 text-transparent bg-clip-text">
     <span className="font-bold">WP-Gypsum paperless sheet</span>
    <span className="text-2xl ml-3 lowercase text-black">by</span>
    <img src={logo2} alt="Logo" className="h-10 ml-4"/>
</h1>

                <p className="text-sm sm:text-2xl text-gray-700 dark:text-white md:text-xl mt-3">
                  This high-performance board has a gypsum core with special additives for moisture and mold resistance. Reinforced with glass mat on both surfaces and coated with a UV-resistant layer, it delivers exceptional performance in humid environments. The board is non-paper faced and free from cellulose, providing strong resistance to mold growth. It is perfect for wet areas and high-humidity environments, including outdoor applications.
                </p>
              
                <div className="flex mt-8 gap-3">
                  
                <Link to="/Paperless" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Read more
                 </Link>

                    
                    <a href="https://wa.me/919819600149" className="uppercase py-4 px-6 rounded-lg bg-gradient-to-r from-cyan-500 to-blue-500 text-white hover:text-white text-md">
                        Get Quote
                    </a>
                </div>
            </div>
            
            <div className="sm:w-1/3 lg:w-3/5 relative order-1 sm:order-2">
               <img src={paperless} className="w-full max-w-lg sm:max-w-full md:max-w-xl mx-auto shadow-2xl " data-aos="zoom-in-left"/> 
            </div>
        </div>
    </div>
</main>


     <Footer></Footer>
    </>
  );
};

export default Plasterboard;
